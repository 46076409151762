import React from "react";
import Image from "../components/Home/Image";
import Personalnfomation from "../components/Home/Personalnfomation";
import SocialMedia from "../components/Home/SocialMedia";

function Home() {
  return (
    <div>
      <Image />
      <Personalnfomation />
      <SocialMedia />
    </div>
  );
}

export default Home;