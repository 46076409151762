import React from "react";
import { projectList } from "../Projects/ProjectList";

function MyProjects() {
  return (
    <div className="mt-16">
      <h1 className="text-3xl font-bold text-center">
        <span className="text-gray-200">
          <u>My Personal Projects</u>
        </span>
      </h1>
      <h1 className="text-xl font-bold text-center mt-7">
        <span className="text-gray-200">
          These projects represent my personal initiatives, while I've
          contributed to more projects in corporate roles, omitted here due to
          confidentiality. 
        </span>
      </h1>
      <h1 className="text-xl font-bold text-center">
        <span className="text-gray-200">
        For a complete list, please refer to my CV.
        </span>
      </h1>
      <div className="mt-12 grid gap-4 md:grid-cols-3 sm:grid-cols-1">
        {projectList.map((project, index) => (
          <div
            key={index}
            className="tech-card card card-compact w-11/12 sm:w-4/4 ml-4 lg:w-full xl:w-4/5 2xl:w-5/6 shadow-xl mb-4"
          >
            <figure className="mt-3">
              <img
                src={project.image}
                alt={project.name}
                width={200}
                height={150}
              />
            </figure>
            <div className="card-body flex flex-col items-center">
              <h2 className="card-title text-slate-200 text-2xl">
                {project.name}
              </h2>
              <p className="text-slate-200 text-xl text-center">
                {project.description}
              </p>
              <div className="flex mt-3">
                <p className="text-slate-200 text-xl text-center">
                  Developed using:{" "}
                </p>
                <div className="tech-icons flex">
                  {project.techs.map((tech, techIndex) => (
                    <img
                      key={techIndex}
                      src={tech}
                      alt={`${tech} icon`}
                      width={35}
                      height={30}
                      className={
                        techIndex < project.techs.length - 1 ? "ml-2 mr-3" : ""
                      }
                    />
                  ))}
                </div>
              </div>
              <div className="mt-3">
                <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`btn btn-neutral mt-2 ${project.github === "" ? "disabled-button" : ""}`}
                    onClick={(e) => {
                        if (project.github === "") {
                            e.preventDefault(); 
                            e.stopPropagation(); 
                        }
                    }}
                >
                    {project.github === "" ? "GitHub Repository (Not Available)" : "GitHub Repository"}
                </a>
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyProjects;
