import React from "react";
import AboutMe from "../components/About/AboutMe";
import AboutGiphy from "../components/About/AboutGiphy";

function About() {
  return (
    <div className="flex flex-col md:flex-row md:space-x-8">
      <AboutMe />
      <AboutGiphy />
    </div>
  );
}

export default About;