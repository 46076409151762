import React from "react";

function Personalnfomation() {
  return (
    <div className="mt-16">
      <h1 className="text-3xl font-bold text-center">
        Hi, I'm <span className=" text-gray-200"> Salahi Erensel</span>
      </h1>
      <div className="mt-4 mx-auto max-w-4xl text-center">
        <p className="text-lg text-slate-200">
          Experienced Software Engineer specializing in Full Stack Web
          development and proficient in designing and developing mobile apps and
          desktop applications. Versatile in meeting diverse customer needs,
          with a strong focus on web app development. Skilled in project
          management and software methodologies, passionate about delivering
          high-quality solutions using cutting-edge technologies.
        </p>
      </div>
    </div>
  );
}

export default Personalnfomation;