import Laravel from "../../assets/TechStack/Laravel.png"
import PostgreSQL from "../../assets/TechStack/Postgresql.png"
import Bootstrap from "../../assets/TechStack/Bootstrap.png"
import DotNet from "../../assets/TechStack/DotNet.png"
import DotNetCore from "../../assets/TechStack/DotNetCore.png"
import CSharp from "../../assets/TechStack/CSharp.png"
import MSSQL from "../../assets/TechStack/Mssql.png"
import ABP from "../../assets/TechStack/ABP.png"
import OracleSql from "../../assets/TechStack/Oracle.png"
import PLSQL from "../../assets/TechStack/PLSQL.png"

export const experienceList = [
    {
        date : "February 2024 - Present",
        company : "KKTCELL",
        position : "Software Engineer",
        techs : [DotNet, DotNetCore, CSharp, OracleSql, PLSQL]
    },
    {
        date : "January 2023 - January 2024",
        company : "Birnet Software",
        position : "Software Engineer",
        techs : [DotNet, DotNetCore, CSharp, MSSQL, ABP, Bootstrap]
    },
    {
        date : "September 2022 - January 2023",
        company : "ORPTech Software",
        position : "Software Engineer",
        techs : [Laravel, PostgreSQL, Bootstrap]
    }
]