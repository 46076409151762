import React from "react";
import MyEducation from "../components/Education/MyEducation";
import EducationGiphy from "../components/Education/EducationGiphy";

function Education() {
  return (
    <div className="flex flex-col md:flex-row md:space-x-8">
      <MyEducation />
      <EducationGiphy />
    </div>
  );
}

export default Education;