import React from "react";
import MyImage from "../../assets/myimage.jpg";

function Image() {
  return (
    <div className="my-image mt-12 flex justify-center">
      <img
        src={MyImage}
        alt="myimage"
        className="rounded-full w-40 h-40 sm:w-64 sm:h-64 md:w-80 md:h-80 lg:w-96 lg:h-96"
      />
    </div>
  );
}

export default Image;