import React from "react";

function Footer() {
  return (
    <footer className="footer footer-center p-5">
      <p className="mt-4 text-slate-200">
        © Copyright Salahi Erensel. All rights reserved. Do not copy, reproduct
        or distribute.
      </p>
    </footer>
  );
}

export default Footer;