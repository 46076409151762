import React from "react";

function EducationGiphy() {
  return (
    <div className="md:w-1/2 flex justify-center items-center mt-8 md:mt-20">
      <div className="hidden md:block">
        <iframe
          title="educationgiphy"
          src="https://giphy.com/embed/69qwNhl6kpVTLRmYC1"
          width="550"
          height="350"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </div>
      <div className="md:hidden">
        <iframe
          title="educationgiphy"
          src="https://giphy.com/embed/69qwNhl6kpVTLRmYC1"
          width="300"
          height="170"
          class="giphy-embed"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default EducationGiphy;