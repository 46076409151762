import React from "react";
import { techStack } from "../TechStack/TechStackList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function MyTechStack() {
  return (
    <div className="mt-16">
      <h1 className="text-3xl font-bold text-center">
        <span className="text-gray-200">
          <u>My Tech Stack</u>
        </span>
      </h1>
      <div className="mt-12 grid gap-4 md:grid-cols-3 sm:grid-cols-1">
        {techStack.map((tech, index) => (
          <div
            key={index}
            className="tech-card card card-compact w-11/12 sm:w-4/4 ml-4 lg:w-full xl:w-4/5 2xl:w-5/6 shadow-xl mb-4"
          >
            <figure className="mt-3">
              <img src={tech.image} alt={tech.name} width={150} height={90} />
            </figure>
            <div className="card-body flex flex-col items-center">
              <h2 className="card-title text-slate-200 text-2xl">
                {tech.name}
              </h2>
              <p className="text-slate-200 text-xl">{tech.level}</p>
              <div className="flex">
                {[...Array(tech.levelNumber)].map((_, i) => (
                  <FontAwesomeIcon key={i} icon={faStar} className="mr-2" />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyTechStack;