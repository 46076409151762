import React, { useState } from "react";
import { sendEmail } from "../../components/Contact/EmailService";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactMe() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      toast.error("Please enter your name!");
      return;
    }
    if (!surname) {
      toast.error("Please enter your surname!");
      return;
    }
    if (!email) {
      toast.error("Please enter your email!");
      return;
    }
    if (!message) {
      toast.error("Please enter your message!");
      return;
    }  

    sendEmail(
      e,
      "service_wxupmgv",
      "template_cnvteps",
      "GXABQmy8JZKf2Hc_f"
    ).then(
      (result) => {
        toast.success("Message sent, Thanks! I will get back to you shortly!");
        setName("");
        setSurname("");
        setEmail("");
        setMessage("");
      },
      (error) => {
        toast.error("An error occurred, please try again later!");
      }
    );
  };

  return (
    <div className="md:w-1/2">
      <ToastContainer />
      <div className="mt-8 md:mt-16 pl-8 sm:pl-4 mr-4 text-center">
        <h1 className="text-3xl font-bold">
          <span className="text-gray-200">
            <u>Contact Me</u>
          </span>
        </h1>
        <form onSubmit={onSubmit}>
          <div className="mt-5 text-left">
            <input
              type="text"
              name="from_name"
              placeholder="Your Name"
              className="input input-bordered w-full max-w-s"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mt-5 text-left">
            <input
              type="text"
              name="from_surname"
              placeholder="Your Surname"
              className="input input-bordered w-full max-w-s"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="mt-5 text-left">
            <input
              type="email"
              name="from_email"
              placeholder="Your Email"
              className="input input-bordered w-full max-w-s"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-5 text-left">
            <textarea
              name="message"
              placeholder="Your Message"
              className="textarea textarea-bordered w-full max-w-s"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="mt-5 text-center">
            <input
              type="submit"
              value="Send Message"
              className="btn btn-neutral btn-wide btn-lg"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactMe;