import React from 'react'
import ContactGiphy from '../components/Contact/ContactGiphy'
import ContactMe from '../components/Contact/ContactMe'

function Contact() {
  return (
    <div className="flex flex-col md:flex-row md:space-x-8">
      <ContactMe/>
      <ContactGiphy/>
    </div>
  )
}

export default Contact