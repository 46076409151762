import React from "react";
import MyTechStack from "../components/TechStack/MyTechStack";

function TechStack() {
  return (
    <div>
      <MyTechStack />
    </div>
  );
}

export default TechStack;