import React from "react";
import { educationList } from "../../components/Education/EducationList";

function MyEducation(){
return(
    <div className="md:w-1/2">
      <div className="mt-8 md:mt-16 pl-8 sm:pl-4 mr-4 text-center">
        <h1 className="text-3xl font-bold">
          <span className="text-gray-200">
            <u>My Education</u>
          </span>
        </h1>
        <div className="mt-4 text-left flex flex-wrap -m-4">
          {educationList.map((education, index) => (
            <div key={index} className="p-4 md:w-1/2 w-full">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="p-6">
                  <h2 className="text-xl text-slate-200 font-semibold">
                    School: {education.school}
                  </h2>
                  <h3 className="text-xl text-slate-200 mt-3">
                    Type: {education.type}
                  </h3>
                  <h3 className="text-xl text-slate-200 mt-3">
                    Program: {education.program}
                  </h3>
                  <p className="text-xl text-slate-200 mt-3">
                    Date: {education.date}
                  </p>
                  <p className="text-xl text-slate-200 mt-3">
                    Location: {education.location}
                  </p>
                  <p className="text-xl text-slate-200 mt-3">
                    CGPA: {education.cgpa}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyEducation;