import React from "react";

function AboutMe() {
  return (
    <div className="md:w-1/2">
      <div className="mt-8 md:mt-16 pl-8 sm:pl-4 mr-4 text-center">
        <h1 className="text-3xl font-bold">
          <span className="text-gray-200">
            <u>About Me</u>
          </span>
        </h1>
        <div className="mt-4 text-left">
          <p className="text-xl text-slate-200 leading-relaxed">
            I am a dedicated Software Engineer with a BSc in Software
            Engineering and currently pursuing an MSc in the same field at
            Eastern Mediterranean University. My expertise spans Full Stack Web
            Application Development, Mobile Application Development, and Desktop
            Applications, backed by four years of practical experience,
            including two years on real-world projects. Fluent in Turkish and
            English, I have a strong foundation in software engineering, with a
            particular emphasis on Agile Methodology and collaborative teamwork.
            I am committed to continuous learning and professional growth,
            leveraging my leadership skills to drive innovation and deliver
            impactful solutions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
