import HTML from "../../assets/TechStack/HTML.png";
import CSS from "../../assets/TechStack/CSS.png";
import Javascript from "../../assets/TechStack/Javascript.png";
import CSharp from "../../assets/TechStack/CSharp.png";
import DotNetCore from "../../assets/TechStack/DotNetCore.png";
import DotNet from "../../assets/TechStack/DotNet.png";
import ABP from "../../assets/TechStack/ABP.png";
import Laravel from "../../assets/TechStack/Laravel.png";
import Java from "../../assets/TechStack/Java.png";
import Nodejs from "../../assets/TechStack/Nodejs.png";
import React from "../../assets/TechStack/React.png";
import Nextjs from "../../assets/TechStack/Nextjs.png";
import MySql from "../../assets/TechStack/Mysql.png";
import PostgreSql from "../../assets/TechStack/Postgresql.png";
import Mssql from "../../assets/TechStack/Mssql.png";
import Supabase from "../../assets/TechStack/Supabase.png";
import Bootstrap from "../../assets/TechStack/Bootstrap.png";
import Figma from "../../assets/TechStack/Figma.png";
import OracleSql from "../../assets/TechStack/Oracle.png";
import PLSql from "../../assets/TechStack/PLSQL.png";

export const techStack = [
  {
    name: "HTML",
    level: "Proficient",
    levelNumber: 5,
    image: HTML,
  },
  {
    name: "CSS",
    level: "Proficient",
    levelNumber: 4,
    image: CSS,
  },
  {
    name: "JavaScript",
    level: "Proficient",
    levelNumber: 4,
    image: Javascript,
  },
  {
    name: "C#",
    level: "Proficient",
    levelNumber: 5,
    image: CSharp,
  },
  {
    name: "ASP .NET Core",
    level: "Proficient",
    levelNumber: 5,
    image: DotNetCore,
  },
  {
    name: "ASP .NET",
    level: "Proficient",
    levelNumber: 5,
    image: DotNet,
  },
  {
    name: "ABP Framework",
    level: "Proficient",
    levelNumber: 4,
    image: ABP,
  },
  {
    name: "PHP & Laravel",
    level: "Competent",
    levelNumber: 3,
    image: Laravel,
  },
  {
    name: "Java",
    level: "Proficient",
    levelNumber: 4,
    image: Java,
  },
  {
    name: "NodeJS",
    level: "Competent",
    levelNumber: 3,
    image: Nodejs,
  },
  {
    name: "ReactJS",
    level: "Proficient",
    levelNumber: 4,
    image: React,
  },
  {
    name: "NextJS",
    level: "Competent",
    levelNumber: 3,
    image: Nextjs,
  },
  {
    name: "MySQL",
    level: "Proficient",
    levelNumber: 5,
    image: MySql,
  },
  {
    name: "PostgreSQL",
    level: "Competent",
    levelNumber: 3,
    image: PostgreSql,
  },
  {
    name: "MSSQL",
    level: "Proficient",
    levelNumber: 5,
    image: Mssql,
  },
  {
    name: "OracleSQL",
    level: "Proficient",
    levelNumber: 5,
    image: OracleSql,
  },
  {
    name: "PL & SQL",
    level: "Proficient",
    levelNumber: 5,
    image: PLSql,
  },
  {
    name: "Supabase",
    level: "Competent",
    levelNumber: 3,
    image: Supabase,
  },
  {
    name: "Bootstrap",
    level: "Proficient",
    levelNumber: 5,
    image: Bootstrap,
  },
  {
    name: "Figma",
    level: "Proficient",
    levelNumber: 4,
    image: Figma,
  },
];