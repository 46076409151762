import React from "react";
import { experienceList } from "../../components/Experiences/ExperienceList";

function MyExperience() {
  return (
    <div className="md:w-1/2">
      <div className="mt-8 md:mt-16 pl-8 sm:pl-4 mr-4 text-center">
        <h1 className="text-3xl font-bold">
          <span className="text-gray-200">
            <u>My Experiences</u>
          </span>
        </h1>
        <div className="mt-4 text-left flex flex-wrap -m-4">
          {experienceList.map((experience, index) => (
            <div key={index} className="p-4 md:w-1/2 w-full">
              <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden">
                <div className="p-6">
                  <h2 className="text-xl text-slate-200 font-semibold">
                    Company: {experience.company}
                  </h2>
                  <h3 className="text-lg text-slate-200 mt-3">
                    Position: {experience.position}
                  </h3>
                  <p className="text-slate-200 mt-3">
                    Date: {experience.date}
                  </p>
                  {(
                    <div className="flex flex-wrap mt-5">
                      <p className="text-slate-200">Techs Used: </p>
                      {experience.techs.map((tech, techIndex) => (
                        <img
                          key={techIndex}
                          src={tech}
                          alt={`${techIndex} logo`}
                          className="w-10 h-10 mr-3 ml-3 mb-2 mt-"
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyExperience;