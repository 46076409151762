import React from "react";
import MyExperience from "../components/Experiences/MyExperience";
import ExperienceGiphy from "../components/Experiences/ExperienceGiphy";

function Experiences() {
  return (
    <div className="flex flex-col md:flex-row md:space-x-8">
      <MyExperience />
      <ExperienceGiphy />
    </div>
  );
}

export default Experiences;