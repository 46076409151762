import React from "react";

function AboutGiphy() {
  return (
    <div className="md:w-1/2 flex justify-center items-center mt-8 md:mt-20">
      <div className="hidden md:block">
        <iframe
          src="https://giphy.com/embed/qgQUggAC3Pfv687qPC"
          width="550"
          height="350"
          className="giphy-embed"
          allowFullScreen
          title="GIPHY Embed"
        ></iframe>
      </div>
      <div className="md:hidden">
        <iframe
          src="https://giphy.com/embed/qgQUggAC3Pfv687qPC"
          width="300"
          height="225"
          className="giphy-embed"
          allowFullScreen
          title="GIPHY Embed"
        ></iframe>
      </div>
    </div>
  );
}

export default AboutGiphy;