import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="navbar">
      <div className="navbar-start flex justify-start">
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost lg:hidden"
            onClick={toggleDropdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          {isOpen && (
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-[#36454F] rounded-box w-52"
            >
              <li>
                <Link to="/" onClick={closeMenu}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={closeMenu}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/techstack" onClick={closeMenu}>
                  Tech Stack
                </Link>
              </li>
              <li>
                <Link to="/projects" onClick={closeMenu}>
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/experiences" onClick={closeMenu}>
                  Experiences
                </Link>
              </li>
              <li>
                <Link to="/education" onClick={closeMenu}>
                  Education
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={closeMenu}>
                  Contact
                </Link>
              </li>
            </ul>
          )}
        </div>
        <img
          src={Logo}
          alt="logo"
          className="rounded-full lg:w-32 lg:h-32 lg:block hidden ml-6"
        />
      </div>
      <div className="navbar hidden lg:flex">
        <ul className="menu menu-horizontal">
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/techstack" onClick={closeMenu}>
              Tech Stack
            </Link>
          </li>
          <li>
            <Link to="/projects" onClick={closeMenu}>
              Projects
            </Link>
          </li>
          <li>
            <Link to="/experiences" onClick={closeMenu}>
              Experiences
            </Link>
          </li>
          <li>
            <Link to="/education" onClick={closeMenu}>
              Education
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;