import React from 'react'
import MyProjects from '../components/Projects/MyProjects'

function Projects() {
  return (
    <div>
      <MyProjects />
    </div>
  )
}

export default Projects